<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <div class="container" style="padding-bottom: 1em">
        <div
          class="box"
          style="
            align-content: flex-start;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
          "
        >
          <h4 class="title2">System Management</h4>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">Run Daily Tasks</p>

            <div class="content">
              <p>
                Run this job to do a daily refresh - all timetable, staff,
                student and parent data are refreshed
              </p>
              <div style="margins: auto; text-align: right">
                <button @click="daily()" class="button is-outlined is-dark">
                  Update
                </button>
              </div>
            </div>
          </article>
          <article class="tile is-child box">
            <p class="title">Run Periodic Tasks</p>

            <div class="content">
              <p>
                Run this job to do an periodic refresh - realtime timetable,
                tags, external files and daybooks are refreshed
              </p>
              <div style="margins: auto; text-align: right">
                <button @click="periodic()" class="button is-outlined is-dark">
                  Update
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "systemManager",

  components: {},

  computed: {},
  methods: {
    daily() {
      this.$api.get("daily/", true, true);
    },

    periodic() {
      this.$api.get("periodic/", true, true);
    },
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
